import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { APP_CONFIG } from '@fgp/app-config';
import { AdminSessionService } from '@fgp/shared/services/admin-session';
import { DeviceDetectorService } from '@fgp/shared/services/device-detector';
import { SubscribeService } from '@fgp/subscribe/data-access';

@Component({
    selector: 'fgp-subscribe-modal-base',
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SubscribeModalBaseComponent implements AfterViewInit {
  isBrowser = this.deviceDetectorService.isBrowser();
  private _verPopUpSubcribe = false;

  set verPopUpSubcribe(value) {
    this._verPopUpSubcribe = value;
    this.cdr.markForCheck();
  }

  get verPopUpSubcribe() {
    return this._verPopUpSubcribe;
  }

  constructor(
    private subscriptionService: SubscribeService,
    private sessionService: AdminSessionService,
    private deviceDetectorService: DeviceDetectorService,
    @Inject(APP_CONFIG) private appConfig,
    private cdr: ChangeDetectorRef
  ) {}

  ngAfterViewInit() {
    if (this.isBrowser && this.isSubscribeModal()) {
      if (!this.subscriptionService.isSuscritoANewsletter()) {
        this.openSubscribeModal();
        this.sessionService.setNewsletter(true); // evitamos que el pop-up vuelva a salir, aunque refresquen la pagina
      }
    }
  }

  public openSubscribeModal(): void {
    this.verPopUpSubcribe = true;
  }

  esconderSubscribe() {
    this.verPopUpSubcribe = false;
  }

  isSubscribeModal(): boolean {
    return this.appConfig.modalSubscribe && !this.subscriptionService.isSuscritoANewsletter();
  }
}
