import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SubscribeModalBaseComponent } from '@fgp/subscribe/feature-subscribe-modal/base';

@Component({
    selector: 'fgp-subscribe-modal-perrelet',
    templateUrl: './subscribe-modal-perrelet.component.html',
    styleUrls: ['./subscribe-modal-perrelet.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SubscribeModalPerreletComponent extends SubscribeModalBaseComponent {}
