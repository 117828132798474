import { NgModule } from '@angular/core';
import { SubscribeFeatureSubscribeModalBaseModule } from '@fgp/subscribe/feature-subscribe-modal/base';
import { SubscribeFeatureSubscribePerreletModule } from '@fgp/subscribe/feature-subscribe/perrelet';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { SubscribeModalPerreletComponent } from './subscribe-modal-perrelet/subscribe-modal-perrelet.component';

@NgModule({
  imports: [SubscribeFeatureSubscribeModalBaseModule, SubscribeFeatureSubscribePerreletModule, SvgIconComponent],
  declarations: [SubscribeModalPerreletComponent],
  exports: [SubscribeModalPerreletComponent]
})
export class SubscribeFeatureSubscribeModalPerreletModule {}
