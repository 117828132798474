<div *ngIf="verPopUpSubcribe" class="card card-plain subcribeContent">
  <div class="modal-content" class="{{ componenteActivoSubscribe }}">
    <div class="modal-header pb-0">
      <button type="button" class="close" aria-label="Close" (click)="esconderSubscribe()">
        <svg-icon size="md" key="x-light" />
      </button>
    </div>
    <fgp-subscribe-perrelet (cerrar)="esconderSubscribe()" [popUP]="true"></fgp-subscribe-perrelet>
  </div>
</div>
