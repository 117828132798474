import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, TranslateModule, LocalizeRouterModule, ReactiveFormsModule],
  exports: [CommonModule, TranslateModule, LocalizeRouterModule, ReactiveFormsModule]
})
export class SubscribeFeatureSubscribeModalBaseModule {}
